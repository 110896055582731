<template>
<div class="">
  <Navbar></Navbar>
  <div class="tenants-wrap">
      <v-card>
       <v-card-title>
         {{$t('tenants.tenants')}}
         <v-spacer></v-spacer>
         <v-text-field
           v-model="search"
           append-icon="search"
           :label="$t('tenants.search')"
           single-line
           hide-details
         ></v-text-field>
       </v-card-title>

       <v-data-table
         :rows-per-page-items='[25,10,5,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
         :headers="headers"
         :items="confirmedOrders"
         :search="search"
         :loading="initialLoad"
         :pagination.sync="pagination"
       >
        <v-progress-linear slot="progress" color="primary" indeterminate ></v-progress-linear>

         <template slot="items" slot-scope="props">
           <tr @click="props.expanded = !props.expanded">
             <td> {{props.item.confirmTimestamp | dateFormat}}</td>

             <td> <a target="blank" :href="props.item.url">{{ props.item.fullRoomNumber }}</a></td>
             <td class="text-xs-right">{{ props.item.tenant.name }}</td>
             <td class="text-xs-right"><span v-if="props.item.status!='Accepted' && props.item.status!='Failed Payment'"> <a :href="'mailto:'+props.item.tenant.email"> {{ props.item.tenant.email }} </a></span><span v-else>{{$t('tenants.waiting')}}...</span></td>
             <td class="text-xs-right">{{ props.item.tenant.nationality}}</td>
             <td class="text-xs-right">{{ props.item.price}}
              <div v-if="props.item.listUtil.amount!=0">{{$t('billing.utilities')}}: {{props.item.listUtil.amount}}</div>
             </td>

             <td class="text-xs-right">{{ props.item.realDates.mid | dateFormat }}</td>
             <td class="text-xs-right">{{ props.item.realDates.mod | dateFormat }}</td>
             <td class="text-xs-right"><span v-if="props.item.status!='Accepted' && props.item.status!='Failed Payment'">{{$t('tenants.paid')}}</span><span v-else>{{$t('tenants.waiting')}} {{props.item.status}}</span></td>
             <td class="text-xs-right">{{ props.item.dates.mid | dateFormat }}</td>
             <td class="text-xs-right">{{ props.item.dates.mod | dateFormat }}</td>
             <td>
               <div v-if="users[props.item.tenantId]!=undefined && users[props.item.tenantId].depositPaid==true">
                <v-icon color="green lighten-2">done_all</v-icon> Deposit has been paid.
               </div >
               <div class="" v-else-if="users[props.item.tenantId]!=undefined && users[props.item.tenantId].deposit!=true && checkTimeline(props.item.dates.mid)" >
                 Ask tenant to pay deposit with credit card. There is no fee for this.

                 <v-btn color="primary" small @click="startDeposit(props.item)">Ask deposit</v-btn>
               </div>
               <div class=""v-else-if="users[props.item.tenantId]!=undefined && users[props.item.tenantId].deposit==true" >
                 Waiting for the tenant to pay ...
                 <v-btn color="error" flat @click="cancelDeposit(props.item)" small>cancel deposit</v-btn>
               </div>
               <div class="" v-else>
                 Please contact our staff to ask for deposit.
               </div>
             </td>
           </tr>
         </template>
         <template slot="expand" slot-scope="props">
           <v-card flat ma-3>
             <v-card-text v-if="users[props.item.tenantId]!=undefined">
               <h3>Birthday</h3>
               {{users[props.item.tenantId].birthday}}
               <h3>About</h3>
               {{users[props.item.tenantId].about}}
               <h3>Preferred Communication Method</h3>
               {{users[props.item.tenantId].communication}}
               <span v-if="users[props.item.tenantId].communication!='Email' && users[props.item.tenantId].appId!=undefined">: {{users[props.item.tenantId].appId}}</span>
               <span v-else-if="users[props.item.tenantId].communication!='Email' && users[props.item.tenantId].appId==undefined">: {{users[props.item.tenantId].phone}}</span>
               <v-data-table
                 :headers="headers2"
                 :items="users[props.item.tenantId].payments"
                 :search="search2"
                 calculate-widths


               >
                 <template slot="items" slot-scope="props">
                   <td>{{props.item.ts | payTS}}</td>
                   <td>{{props.item.total}} TW$</td>
                   <td>{{props.item.comment}}</td>
                 </template>
               </v-data-table>
             </v-card-text>
           </v-card>
         </template>

         <v-alert slot="no-results" :value="true" icon="warning">
           No result for your search
         </v-alert>
       </v-data-table>
     </v-card>
   </div>
  <v-dialog
    v-model="depositDialog"
    persistent :overlay="false"
    max-width="350px"
    transition="dialog-transition"
  >
  <v-card>
    <v-card-title primary-title>
      Add a deposit <span v-if="currentCR.hasOwnProperty('fullRoomNumber')">for {{currentCR.fullRoomNumber}}</span>
    </v-card-title>
    <v-card-text>
      <v-subheader class="mx-0 px-0">
        Please confirm the deposit amount. (This is the amount we will transfer you)
      </v-subheader>
      <v-text-field
       label="Deposit amount"
       number
       v-model="deposit"
       suffix="NT$"
      ></v-text-field>
    </v-card-text>
    <v-layout justify-center wrap>

      <div class="" v-if="working">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
    </v-layout>
    <v-card-actions>
      <v-btn color="error" flat :disable="working" @click="depositDialog=false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" :disable="working" flat small @click="saveDeposit">Confirm</v-btn>
    </v-card-actions>
  </v-card>
  </v-dialog>
  <v-snackbar
    v-model="showSuccess"
    color="primary"
    :timeout="6000"
  >
    Deposit Added
    <v-btn flat @click.native="showSuccess = false">Close</v-btn>
  </v-snackbar>

  <v-snackbar
    v-model="showDeleted"
    color="error"
    :timeout="6000"
  >
    Deposit Deleted
    <v-btn flat @click.native="showDeleted = false">Close</v-btn>
  </v-snackbar>

</div>
</template>
<script>
import Loading from '../shared/Loading.vue'
import {
  mapGetters
} from 'vuex'
import countries from "i18n-iso-countries"
import axios from 'axios'
import * as types from '../../store/types'
import firebase from '../../FirebaseApp'
import moment from 'moment'

export default {
  name: "",
  data: () => ({
    confirmedOrders:[],
    search: null,
    searched: [],
    depositDialog:false,
    users: {},
    currentCR:{},
    initialLoad:true,
    deposit:"",
    pagination: {
      descending: true

    },
    working:false,
    showSuccess:false,
    showDeleted:false,
    headers2:[
      { text:"Date Paid", value:"ts", width:"1%"},
      { text: "Amount", value: 'total' , width:"1%"},
      { text: "Comment", value: 'comment' , width:"98%"},
    ],
    search2:null,
  }),

  computed: {
    ...mapGetters({
      user: types.GET_USER
    }),

    userId(){
      if (this.$route.query!=undefined && this.$route.query.id!=undefined && this.user.roleValue>20){
        return this.$route.query.id
      } else {
        // console.log('no id');
        return this.user.id
      }
    },
    headers(){
      let t=[
        { text:"Date Confirmed", value:"confirmTimestamp"},
        { text: "Room", value: 'fullRoomNumber' },
        { text: "Name", value: 'tenant/name' },
        { text: "Email", value: 'tenant/email' },
        { text: "Nationality", value: 'tenant/nationality'},
        { text: "Price accepted", value: "price"},
        { text: "Actual Move In", value: 'realDates/mid' },
        { text: "Actual Move Out", value: 'realDates/mod' },
        { text: 'First month rent', value: 'status' },
        { text: "Contract Start", value: 'dates/mod' },
        { text: "Contract End", value: 'dates/mod' },
      ]
      if (this!=undefined){
          t=[
          { text:"Date Confirmed", value:"confirmTimestamp"},
          { text: this.$t('tenants.room'), value: 'fullRoomNumber' },
          { text: this.$t('tenants.name'), value: 'tenant/name' },
          { text: this.$t('tenants.email'), value: 'tenant/email' },
          { text: this.$t('tenants.nationality'), value: 'tenant/nationality'},
          { text: this.$t('billing.rent'), value: "price"},
          { text: this.$t('tenants.mid'), value: 'realDates/mid' },
          { text: this.$t('tenants.mod'), value: 'realDates/mod' },
          { text: this.$t('tenants.rent'), value: 'status' },
          { text: this.$t('tenants.cmid'), value: 'dates/mod' },
          { text: this.$t('tenants.cmod'), value: 'dates/mod' },
          { text: 'Deposit', sortable:false}
          ]
       }
       return t
    }

  },
  firebase() {
    return {
      orders: {
        source: firebase.database().ref('completedOrders').orderByChild('landlordId').equalTo(this.userId),
        readyCallback: function() {

          countries.registerLocale(require("i18n-iso-countries/langs/"+this.$i18n.locale+".json"))
          for (var i = 0; i < this.orders.length; i++) {
            this.orders[i].url=this.getUrl(this.orders[i])
            this.orders[i].tenant.nationality=countries.getName(this.orders[i].tenant.nationality,this.$i18n.locale)
            // console.log(this.orders[i].status);
            this.getUser(this.orders[i].tenantId)
            if (this.orders[i].status!='Canceled'){
              this.confirmedOrders.push(this.orders[i])
            }
          }
          this.initialLoad=false
        },
      },
    }
  },
  methods:{
    getUser(id){
      // console.log('get user ',id);
      axios.get('/users/'+id+'.json')
      .then(res=>{
        // console.log(res.data);
        this.users[id]=res.data
        this.getPayments(id)
        this.pagination.descending=!this.pagination.descending
        this.pagination.descending=!this.pagination.descending
      })
      .catch(err=>{
        console.log(err);
      })
    },
    getPayments(id){
      this.users[id].payments=[]
      axios.get('/payIn.json?orderBy="tenantId"&equalTo="' + id + '"')
      .then(res=>{
        //
        for (var t in res.data) {
          if (res.data.hasOwnProperty(t)) {
            res.data[t].key=t
            this.users[id].payments.push(res.data[t])
          }
        }
      })
      .catch(err=>{
        console.log(err);
      })
    },
    startDeposit(cr){
      // console.log(cr);
      this.currentCR=cr
      this.depositDialog=true
      // console.log(this.currentCR, this.depositDialog);
    },
    getUrl(item){
      switch (item.type) {
        case 'room':
          return '/details/'+item.listingId
        case 'studio':
          return '/studioDetails/'+item.listingId
        case 'apartment':
          return '/apartmentDetails/'+item.listingId
        default:
          return '/dashboard'

      }
    },
    cancelDeposit(cr){
      this.working=true
      this.users[cr.tenantId].deposit=false
      axios.get('rents.json?orderBy="tenantId"&equalTo="'+cr.tenantId+'"')
      .then(res=>{
        let rents=res.data
        for (var id in rents) {
          if (rents.hasOwnProperty(id)) {
            let tRent=rents[id]
            if (tRent.hasOwnProperty('deposit') && tRent.deposit==true){
              axios.delete('rents/'+id+'.json?auth=' + this.$store.state.auth.idToken)
              .then(res=>{
                console.log('deleted');
                this.working=false
                this.showDeleted=true
              })
              axios.patch('users/'+cr.tenantId+'.json?auth='+ this.$store.state.auth.idToken,{deposit:false})
            }
          }
        }
      })
    },
    saveDeposit(){
      this.users[this.currentCR.tenantId].deposit=true
      this.working=true
      let t={
        deposit:true,
        comment:this.currentCR.fullRoomNumber+ " - Deposit Payment",
        brId:this.currentCR.id,
        crId:this.currentCR['.key'],
        currency:"TWD",
        dates:this.currentCR.dates,
        invoiceSent:true,
        landlordId:this.currentCR.landlordId,
        listingId:this.currentCR.listingId,
        price:this.deposit,
        realDates:this.currentCR.realDates,
        roomNumber:this.currentCR.fullRoomNumber,
        tenantId:this.currentCR.tenantId,
        type:this.currentCR.type,
      }
      axios.post('rents.json?auth=' + this.$store.state.auth.idToken, t)
      .then(res=>
        axios.patch('users/'+this.currentCR.tenantId+'.json?auth=' + this.$store.state.auth.idToken, {deposit:true})
        .then(res=>{
          this.working=false
          this.showSuccess=true
          this.depositDialog=false
          this.deposit=null
          this.currentCR={}
        })
      )
      // console.log(t);
    },

    checkTimeline(date){
      if (moment().isBefore(moment(date).add(30,'days'))){
        return true
      } else {
        return false
      }
    }
  },
  created() {
  },
  filters:{
    dateFormat: function(t) {
      return moment(t).format('ll')
    },
    payTS: function(t) {
      return moment(t,"YYMMDD").format('ll')
    },

  },

  watch: {
  },
  components: {
    Loading,

  }

}
</script>
<style lang="scss" scoped>
.tenants-wrap{
  margin:5vh 2.5vw;
  width: 95vw;
}
.pa-3.top-padding-only {
    padding-bottom: 0px!important;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #00bfff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}

</style>
